import { memo, useCallback, useEffect, useRef, useState, type JSX } from "react";

import Grid from "@mui/material/Grid";
import { styled, Theme } from "@mui/material/styles";
import { AccessTokenError } from "@simplyk/common";
import { useForm } from "react-hook-form";

import { ContactSupportLink } from "../../components/ContactSupportLink";
import { Button } from "../../components/design-system/Button";
import { TextField } from "../../components/design-system/TextField";
import { Typography } from "../../components/design-system/Typography";
import { AmplitudeEvents } from "../../constants/amplitude";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { SignInFrontendInput } from "../../gql/gql-types";
import { SignInMutation, useResendTwoFactorCodeMutation } from "../../gql/queries/generated/authQuery";
import { formatSignInData } from "../../helpers/authFormat";
import { submitHubspotForm } from "../../helpers/hubspot";
import { getLastUserView } from "../../helpers/localStorage";
import { isRequired } from "../../helpers/validators";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTranslate } from "../../hooks/useTranslate";

import { SignInData } from "./types";

const PREFIX = "TwoFactorAuth";

const classes = {
  bold: `${PREFIX}-bold`,
  form: `${PREFIX}-form`,
  alignText: `${PREFIX}-alignText`,
};

const StyledForm = memo(
  styled("form")(({ theme }: { theme: Theme }) => ({
    [`&& .${classes.bold}`]: {
      fontWeight: theme.typography.fontWeightBold,
    },

    [`&& .${classes.form}`]: {
      width: "100%",
      marginTop: theme.spacing(2),
    },

    [`&& .${classes.alignText}`]: {
      textAlign: "center",
    },
  }))
);

interface EnterPasswordProps {
  email: string;
  password: string;
  loginBackButton: JSX.Element;
  signin: (signInInput: SignInFrontendInput) => Promise<void | SignInMutation | null>;
}

export const TwoFactorAuth = ({ email, password, loginBackButton, signin }: EnterPasswordProps) => {
  const { t } = useTranslate();
  const { isPhoneScreen } = useMediaQuery();

  const [isLoading, setIsLoading] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const { logAmplitudeEvent, logAmplitudeEventCallback } = useAmplitude();
  const [execResendCode] = useResendTwoFactorCodeMutation();
  const { locale } = useLocaleContext();

  const currentUserId = useRef<string | null>(null);

  const form = useForm<SignInData>({
    reValidateMode: "onChange",
    shouldUnregister: true,
  });

  const resendCode = useCallback(async () => {
    logAmplitudeEvent(AmplitudeEvents.UserSignIn2FACodeResent);
    const lastUserView = currentUserId.current ? getLastUserView(currentUserId.current) : undefined;
    await execResendCode({
      variables: {
        signInInput: formatSignInData({ email, password }, locale, lastUserView?.organizationId),
      },
    });
    setIsCodeSent(true);
    setTimeout(() => {
      setIsCodeSent(false);
    }, 800);
  }, [email, execResendCode, locale, logAmplitudeEvent, password]);

  const handleClickOnField = useCallback(() => {
    if (form.formState.errors["twoFactorCode"]?.message) {
      logAmplitudeEvent(AmplitudeEvents.LoginFocusTwoFactorCodeAfterError);
    } else {
      logAmplitudeEvent(AmplitudeEvents.LoginFocusTwoFactorCode);
    }
  }, [form.formState.errors, logAmplitudeEvent]);

  const onSubmit = form.handleSubmit(async (data: SignInData) => {
    setIsLoading(true);
    submitHubspotForm({ ...data, pageName: "Sign In" });

    logAmplitudeEvent(AmplitudeEvents.LoginClickLoginTwoFactorCode);
    const lastUserView = currentUserId.current ? getLastUserView(currentUserId.current) : undefined;

    const signInResponse = await signin(
      formatSignInData({ ...data, email, password }, locale, lastUserView?.organizationId)
    );

    if (signInResponse?.signIn?.error?.code === AccessTokenError.TwoFactorCodeExpired) {
      form.setError("twoFactorCode", {
        type: "manual",
        message: t("dashboard", "signin.twoFactor.codeExpired"),
      });
    }

    if (signInResponse?.signIn?.error?.code === AccessTokenError.TwoFactorCodeDoesNotMatch) {
      form.setError("twoFactorCode", {
        type: "manual",
        message: t("dashboard", "signin.twoFactor.wrongCode"),
      });
    }

    setIsLoading(false);
  });

  /**
   * Log the page view event
   */
  useEffect(() => {
    logAmplitudeEvent(AmplitudeEvents.UserSignIn2FAStarted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isPhoneScreen && <Grid marginLeft={-2}>{loginBackButton}</Grid>}
      <StyledForm className={classes.form} onSubmit={onSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="h4">{t("dashboard", `signin.twoFactor.title`)}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {t("dashboard", "signin.twoFactor.body.part1")}
                      <span className={classes.bold}>{email}</span>
                      {t("dashboard", "signin.twoFactor.body.part2")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      noAsterisk
                      id="twoFactorCode"
                      name="twoFactorCode"
                      autoFocus
                      control={form.control}
                      errorMessage={form.formState.errors["twoFactorCode"]?.message}
                      rules={[isRequired(t("dashboard", "common.isRequired"))]}
                      inputProps={{ maxLength: 6 }}
                      onClick={handleClickOnField}
                      placeholder={t("dashboard", "signin.twoFactor.codePlaceHolder")}
                      onBlur={logAmplitudeEventCallback(AmplitudeEvents.UserSignInInputChanged, {
                        input_name: "twoFactorCode",
                        valid_field: !form.formState.errors["twoFactorCode"],
                        field_empty: !form.getValues("twoFactorCode"),
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} marginTop={4}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Button type="submit" fullWidth variant="filled" vibe="brand" isLoading={isLoading}>
                  {t("dashboard", "signin.connection")}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button fullWidth variant="outlined" vibe="brand" onClick={resendCode} disabled={isCodeSent}>
                  {t("dashboard", isCodeSent ? "signin.twoFactor.codeSent" : "signin.twoFactor.resendCode")}
                </Button>
              </Grid>
              <Grid item xs={12} className={classes.alignText}>
                {t("dashboard", "signin.twoFactor.cannotAccessCode")} <ContactSupportLink />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledForm>
    </>
  );
};
