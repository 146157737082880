import { useCallback, useContext } from "react";

import { FormCategory, getCountryShortCodeFromOrganizationCountry, OrganizationCountry } from "@simplyk/common";

import { isDevelopment, isStaging } from "../constants/env";
import { ConsentModeContext } from "../contexts/ConsentModeContext";

type WindowWithDataLayer = Window & {
  dataLayer?: Record<string, unknown>[];
};

declare const window: WindowWithDataLayer;

interface SignupStep1Payload {
  email?: string;
}

interface SignupStep2Payload {
  email?: string;
  firstName: string;
  lastName: string;
  charityNumber?: string;
  country: OrganizationCountry;
  region?: string;
}

interface SignupStep3Payload {
  email?: string;
  firstName?: string;
  lastName?: string;
  charityNumber?: string;
  country: OrganizationCountry;
  region?: string;
  formCategory: FormCategory;
}

interface CreatedFormPayload {
  email?: string;
  charityNumber?: string;
  country: OrganizationCountry;
  region?: string;
  formCategory: FormCategory;
}

interface BankConnectedPayload {
  email?: string;
  charityNumber?: string;
  country: OrganizationCountry;
}

interface BankConnectedWithReferalAnswerPayload {
  email?: string;
  charityNumber?: string;
  country: OrganizationCountry;
  referalAnswer: string;
}

interface FirstPagePayload {
  email: string;
  paymentCount: number;
}

interface EmailPayload {
  email?: string;
}

const logGtmEvent = (payload: Record<string, unknown>) => {
  if (isStaging || isDevelopment) {
    // eslint-disable-next-line no-console
    console.log("GTM event", payload);
  }
};

const pushAndLogGtmEvent = (payload: Record<string, unknown>) => {
  if (typeof window !== "undefined" && Array.isArray(window.dataLayer)) {
    window.dataLayer?.push(payload);
  }
  logGtmEvent(payload);
};

export const useGtm = () => {
  const { isUserInQuebec } = useContext(ConsentModeContext);

  const transformGtmPayload = useCallback(
    <T extends { email?: string | null }>(payload: T): T => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { email, ...payloadWithoutEmail } = payload;
      return isUserInQuebec ? (payloadWithoutEmail as T) : payload;
    },
    [isUserInQuebec]
  );

  const pushGtmSignUpStep1 = useCallback(
    (payloadParam: SignupStep1Payload) => {
      const payload = transformGtmPayload(payloadParam);
      const dataLayoutPayload = {
        event: "sign_up_step1",
        user_informations: {
          user_email: payload.email,
        },
      };
      pushAndLogGtmEvent(dataLayoutPayload);
    },
    [transformGtmPayload]
  );

  const pushGtmSignUpStep2 = useCallback(
    (payloadParam: SignupStep2Payload) => {
      const payload = transformGtmPayload(payloadParam);
      const dataLayoutPayload = {
        event: "sign_up",
        user_informations: {
          user_email: payload.email,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          user_firstName: payload.firstName,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          user_lastName: payload.lastName,
          user_country: getCountryShortCodeFromOrganizationCountry(payload.country),
        },
        org_informations: {
          org_charity_number_entered: Boolean(payload.charityNumber),
          org_charity_number: payload.charityNumber,
          org_country: getCountryShortCodeFromOrganizationCountry(payload.country),
          org_region: payload.region,
        },
      };
      pushAndLogGtmEvent(dataLayoutPayload);
    },
    [transformGtmPayload]
  );

  const pushGtmSignUpStep3 = useCallback(
    (payloadParam: SignupStep3Payload) => {
      const payload = transformGtmPayload(payloadParam);
      const dataLayoutPayload = {
        event: "sign_up_service_entry",
        user_informations: {
          user_email: payload.email,
        },
        org_informations: {
          org_charity_number_entered: Boolean(payload.charityNumber),
          org_charity_number: payload.charityNumber,
          org_country: getCountryShortCodeFromOrganizationCountry(payload.country),
          org_region: payload.region,
          org_first_form_category: payload.formCategory,
        },
      };
      pushAndLogGtmEvent(dataLayoutPayload);
    },
    [transformGtmPayload]
  );
  const pushGtmFirstCreatedForm = useCallback(
    (payloadParam: CreatedFormPayload) => {
      const payload = transformGtmPayload(payloadParam);
      const dataLayoutPayload = {
        event: "first_created_form",
        user_informations: {
          user_email: payload.email,
        },
        org_informations: {
          org_charity_number_entered: Boolean(payload.charityNumber),
          org_charity_number: payload.charityNumber,
          org_country: getCountryShortCodeFromOrganizationCountry(payload.country),
          org_region: payload.region,
          org_first_form_category: payload.formCategory,
        },
      };
      pushAndLogGtmEvent(dataLayoutPayload);
    },
    [transformGtmPayload]
  );

  const pushGtmSignupConnectingBankStep1 = useCallback(
    (payloadParam: BankConnectedPayload) => {
      const payload = transformGtmPayload(payloadParam);
      const dataLayoutPayload = {
        event: "signup_connecting_bank_step_1_started",
        user_informations: {
          user_email: payload.email,
        },
        org_informations: {
          org_charity_number: payload.charityNumber,
          org_country: payload.country,
        },
      };
      pushAndLogGtmEvent(dataLayoutPayload);
    },
    [transformGtmPayload]
  );

  const pushGtmSignupConnectingBankStep2 = useCallback(
    (payloadParam: BankConnectedWithReferalAnswerPayload) => {
      const payload = transformGtmPayload(payloadParam);
      const dataLayoutPayload = {
        event: "signup_connecting_bank_step_2_submited",
        user_informations: {
          user_email: payload.email,
        },
        org_informations: {
          org_charity_number: payload.charityNumber,
          org_country: payload.country,
          org_referal_answer: "",
        },
      };
      pushAndLogGtmEvent(dataLayoutPayload);
    },
    [transformGtmPayload]
  );

  const pushGtmBankConnected = useCallback(
    (payloadParam: BankConnectedPayload) => {
      const payload = transformGtmPayload(payloadParam);
      const dataLayoutPayload = {
        event: "signup_bank_connected",
        user_informations: {
          user_email: payload.email,
        },
        org_informations: {
          org_charity_number_entered: Boolean(payload.charityNumber),
          org_charity_number: payload.charityNumber,
          org_country: getCountryShortCodeFromOrganizationCountry(payload.country),
        },
      };
      pushAndLogGtmEvent(dataLayoutPayload);
    },
    [transformGtmPayload]
  );

  const pushGtmLogin = useCallback(
    (payloadParam: EmailPayload) => {
      const payload = transformGtmPayload(payloadParam);
      const dataLayoutPayload = {
        event: "login",
        user_informations: {
          user_email: payload.email,
        },
      };
      pushAndLogGtmEvent(dataLayoutPayload);
    },
    [transformGtmPayload]
  );

  const pushGtmFirstPage = useCallback(
    (payloadParam: FirstPagePayload) => {
      const payload = transformGtmPayload(payloadParam);
      const dataLayoutPayload = {
        event: "first_page",
        user_informations: {
          user_email: payload.email,
        },
        org_informations: {
          payment_count: payload.paymentCount,
        },
      };
      pushAndLogGtmEvent(dataLayoutPayload);
    },
    [transformGtmPayload]
  );

  const pushGtmFormInputChanged = useCallback((count: number) => {
    const dataLayoutPayload = {
      event: "form_input_changed",
      form_input_changed_count: count,
    };
    pushAndLogGtmEvent(dataLayoutPayload);
  }, []);

  return {
    pushGtmSignUpStep1,
    pushGtmSignUpStep2,
    pushGtmSignUpStep3,
    pushGtmFirstCreatedForm,
    pushGtmSignupConnectingBankStep1,
    pushGtmSignupConnectingBankStep2,
    pushGtmBankConnected,
    pushGtmLogin,
    pushGtmFirstPage,
    pushGtmFormInputChanged,
  };
};
