import { useCallback, useEffect } from "react";

import { useCurrentUserContext } from "../contexts/CurrentUserContext";
import { FrontendFormObject } from "../gql/gql-types";
import { useGetApplicationInitialStatusQuery } from "../gql/queries/generated/applicationQuery";

import { useAppDispatch } from "@/hooks/redux";
import { load, skipApplicationInitialStatusLoad } from "@/redux/reducers/application-initial-status.reducer";

export const useApplicationInitialStatus = (): {
  applicationInitialStatusLoading: boolean;
  refetchApplicationInitialStatus: () => Promise<void>;
} => {
  const { isOrganization, isAuthenticated, userIsLoading } = useCurrentUserContext();
  const {
    data,
    loading: dataLoading,
    refetch,
  } = useGetApplicationInitialStatusQuery({ skip: !isOrganization, fetchPolicy: "network-only" });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data?.getApplicationInitialStatus?.object) {
      dispatch(
        load({
          ...data?.getApplicationInitialStatus?.object,
          latestForm: data.getApplicationInitialStatus.object.latestForm as FrontendFormObject,
        })
      );
    } else if (!dataLoading && !userIsLoading && !isOrganization) {
      dispatch(skipApplicationInitialStatusLoad());
    }
  }, [data?.getApplicationInitialStatus, dataLoading, dispatch, isAuthenticated, isOrganization, userIsLoading]);

  const refetchApplicationInitialStatus = useCallback(async () => {
    const { data } = await refetch();
    if (data?.getApplicationInitialStatus?.object) {
      dispatch(
        load({
          ...data?.getApplicationInitialStatus?.object,
          latestForm: data.getApplicationInitialStatus.object.latestForm as FrontendFormObject,
        })
      );
    }
  }, [dispatch, refetch]);

  return {
    applicationInitialStatusLoading: dataLoading,
    refetchApplicationInitialStatus,
  };
};
