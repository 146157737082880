import { memo, PropsWithChildren, useEffect } from "react";

import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { styled, Theme } from "@mui/material/styles";
import Image from "next/image";

import { Button } from "../../components/design-system/Button";
import { Typography } from "../../components/design-system/Typography";
import { ZeffyLogoRedirect } from "../../components/ZeffyLogoRedirect/ZeffyLogoRedirect";
import { AmplitudeEvents } from "../../constants/amplitude";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTranslate } from "../../hooks/useTranslate";

import { CheckEmail } from "./CheckEmail";
import DonorLogin from "./DonorLogin";
import { EnterPassword } from "./EnterPassword";
import { EnterSMSCode } from "./EnterSmsCode";
import ResetPassword from "./ResetPassword";
import { TwoFactorAuth } from "./TwoFactorAuth";
import { SignInStep, useSignIn } from "./useSignIn";

import { ChevronLeft } from "@/icons/outlined";

const PREFIX = "SignIn";

const classes = {
  page: `${PREFIX}-page`,
  header: `${PREFIX}-header`,
  paper: `${PREFIX}-paper`,
  container: `${PREFIX}-container`,
  alignText: `${PREFIX}-alignText`,
  card: `${PREFIX}-card`,
  logoContainer: `${PREFIX}-logoContainer`,
  flexBasis: `${PREFIX}-flexBasis`,
};

const Root = memo(
  styled("div")(({ theme }: { theme: Theme }) => ({
    [`&&.${classes.page}`]: {
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "flex-start",
        backgroundColor: theme.palette.primary.main,
        backgroundImage: "url(/images/login-wave.svg)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      },
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
      },
    },

    [`&& .${classes.header}`]: {
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(0, 12),
      },
    },

    [`&& .${classes.paper}`]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      justifyContent: "center",
    },

    [`&& .${classes.container}`]: {
      height: "100%",
    },

    [`&& .${classes.alignText}`]: {
      textAlign: "center",
    },

    [`&& .${classes.card}`]: {
      [theme.breakpoints.up("sm")]: {
        backgroundColor: "white",
        padding: theme.spacing(6, 8),
        borderRadius: theme.radius(3),
        width: 554,
        boxShadow: theme.boxShadow.base.strong,
      },
    },

    [`&& .${classes.logoContainer}`]: {
      height: "100%",
      alignItems: "center",
      display: "flex",
    },

    [`&& .${classes.flexBasis}`]: {
      flexBasis: "100%",
    },
  }))
);

const SignIn: React.FunctionComponent<PropsWithChildren<unknown>> = () => {
  const { t } = useTranslate();
  const { logAmplitudeEvent } = useAmplitude();

  const {
    signInStep,
    setSignInStep,
    email,
    handleChangeEmail,
    redirectToResetPassword,
    displayBackButton,
    loginBack,
    loginBackButton,
    redirectToLogin,
    password,
    setPassword,
    signin,
    stytchVerdict,
    maskedPhoneNumber,
  } = useSignIn();
  const { isPhoneScreen } = useMediaQuery();

  /**
   * Log the page view event
   */
  useEffect(() => {
    logAmplitudeEvent(AmplitudeEvents.UserSignInPageViewed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Root className={classes.page}>
      <Container className={classes.container}>
        <Grid item className={classes.paper}>
          <Grid item xs={12} className={classes.flexBasis} />
          <Grid container spacing={1} className={classes.card}>
            {isPhoneScreen && (
              <Grid item xs={12}>
                <Grid container className={classes.header} justifyContent="space-between" alignItems="center">
                  <Grid item xs={12}>
                    {displayBackButton ? (
                      <Grid container alignItems="center" marginTop={1.25} marginBottom={1.25}>
                        <Grid item xs={2}>
                          <Button vibe="neutral" iconButton onClick={loginBack}>
                            <ChevronLeft />
                          </Button>
                        </Grid>
                        <Grid item xs={8} className={classes.alignText}>
                          <Typography variant="subtitle1">{t("common", "back")}</Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <ZeffyLogoRedirect src="/images/zeffy-logo.png" width={80} height={24} />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {signInStep === SignInStep.CheckEmail && (
              <Grid item xs={12}>
                <CheckEmail
                  signin={signin}
                  setSignInStep={setSignInStep}
                  handleChangeEmail={handleChangeEmail}
                  email={email}
                />
              </Grid>
            )}
            {signInStep === SignInStep.EnterPassword && (
              <Grid item xs={12}>
                <EnterPassword
                  signin={signin}
                  email={email}
                  redirectToResetPassword={redirectToResetPassword}
                  setPassword={setPassword}
                  loginBackButton={loginBackButton}
                />
              </Grid>
            )}
            {signInStep === SignInStep.TwoFactorAuth && (
              <Grid item xs={12}>
                <TwoFactorAuth signin={signin} email={email} password={password} loginBackButton={loginBackButton} />
              </Grid>
            )}
            {signInStep === SignInStep.EnterSMSCode && (
              <Grid item xs={12}>
                <EnterSMSCode
                  signin={signin}
                  email={email}
                  password={password}
                  maskedPhoneNumber={maskedPhoneNumber}
                  loginBackButton={loginBackButton}
                  stytchVerdict={stytchVerdict}
                />
              </Grid>
            )}
            {signInStep === SignInStep.ResetPassword && (
              <Grid item xs={12}>
                <ResetPassword defaultEmail={email} redirectToLogin={redirectToLogin} />
              </Grid>
            )}
            {signInStep === SignInStep.DonorLogin && (
              <Grid item xs={12}>
                <DonorLogin defaultEmail={email} redirectToLogin={redirectToLogin} />
              </Grid>
            )}
          </Grid>
          {!isPhoneScreen && (
            <Grid item xs={12} className={classes.logoContainer}>
              <Image src="/images/Zeffy-Logo-White.svg" alt="Zeffy logo" width={139} height={42} />
            </Grid>
          )}
        </Grid>
      </Container>
    </Root>
  );
};

export default SignIn;
