import { GoogleOAuthProvider } from "@react-oauth/google";
import { Locales } from "@simplyk/common";
import { useRouter } from "next/router";

import { AppFrontendProvider } from "../components/AppFrontendProvider/AppFrontendProvider";
import { ZeffyHead } from "../components/Head/ZeffyHead";
import { RedirectIfAuthenticatedOnPublicRoute } from "../components/RedirectIfAuthenticatedOnPublicRoute/RedirectIfAuthenticatedOnPublicRoute";
import SignIn from "../dashboard-pages/SignIn/SignIn";
import { getTranslation } from "../helpers/getStaticProps";
import { TunnelLayout } from "../layouts/Tunnel/TunnelLayout";
import { ServerSideContext } from "../types/app";

const Login = () => {
  const router = useRouter();
  const { locale: i18nextLocale } = router;
  const locale = i18nextLocale?.toLocaleUpperCase().startsWith(Locales.EN) ? Locales.EN : Locales.FR;

  const title = locale === Locales.EN ? "Log in | Zeffy" : "Se connecter | Zeffy (Log in | Zeffy)";
  const description =
    locale === Locales.EN
      ? "Access your Zeffy account securely. Manage fundraising forms, your payments and your donor interactions."
      : "Accédez en toute sécurité à votre compte Zeffy. Gérez facilement les campagnes de collecte de fonds, les interactions avec les donateurs et vos paiements.";

  return (
    <>
      {/* Nothing should wrap or block the Head component */}
      <ZeffyHead title={title} description={description} />
      <AppFrontendProvider skipUserConsentModal={false}>
        <RedirectIfAuthenticatedOnPublicRoute layout={TunnelLayout} noScreenPadding>
          <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || ""}>
            <SignIn />
          </GoogleOAuthProvider>
        </RedirectIfAuthenticatedOnPublicRoute>
      </AppFrontendProvider>
    </>
  );
};

export const getStaticProps = async (context: ServerSideContext<null>) => {
  return {
    props: await getTranslation(context.locale),
  };
};

export default Login;
