import * as React from "react";

import { UserRoles, RoutePrefix } from "@simplyk/common";
import { useRouter } from "next/router";

import { useCurrentUserContext } from "../contexts/CurrentUserContext";
import { View } from "../enums/view";
import { getLastUserView } from "../helpers/localStorage";
import { useView } from "../hooks/useView";

export const useViewProvider = () => {
  const router = useRouter();
  const { currentUser, userRole, userIsLoading } = useCurrentUserContext();
  const { view: currentView, setView } = useView();

  const targetOrganizationId = router.query.targetOrganizationId ? (router.query.targetOrganizationId as string) : null;

  const defaultView: View | null = React.useMemo(() => {
    if (userIsLoading || !userRole) {
      return null;
    }
    if (!targetOrganizationId && getLastUserView(currentUser?.id)?.view === View.Donor) {
      return View.Donor;
    }

    if (
      router.pathname.startsWith(RoutePrefix.Organization) &&
      (userRole === UserRoles.Owner || userRole === UserRoles.Member)
    ) {
      return View.Organization;
    }

    return userRole === UserRoles.Donor ? View.Donor : View.Organization;
  }, [currentUser?.id, targetOrganizationId, router.pathname, userIsLoading, userRole]);

  // this use effect handles the first load of the page
  React.useEffect(() => {
    if (defaultView && !currentView) {
      setView(defaultView);
    }
  }, [currentView, defaultView, router, setView]);
};
