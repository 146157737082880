import { HubspotAccountOwner, HubspotUserStatus } from "@simplyk/common";

import { isProduction } from "../constants/env";

import { getCookieByName } from "@/helpers/cookie";

interface HubspotForm {
  email: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  isOwner?: boolean;
  pageName: string;
}

export const submitHubspotForm = async ({
  email,
  firstName,
  lastName,
  phoneNumber,
  isOwner,
  pageName,
}: HubspotForm) => {
  if (!isProduction) {
    return;
  }
  const getIpResponse = await fetch(process.env.NEXT_PUBLIC_API_URL + "/application/ip", { method: "GET" });
  const { ip } = (await getIpResponse.json()) as { ip?: string };
  const fields = [
    {
      objectTypeId: "0-1",
      name: "email",
      value: email,
    },
    {
      objectTypeId: "0-1",
      name: "firstname",
      value: firstName || "", // undefined break the submission, but empty string does not overwrite the property
    },
    {
      objectTypeId: "0-1",
      name: "lastname",
      value: lastName || "", // undefined break the submission, but empty string does not overwrite the property
    },
    {
      objectTypeId: "0-1",
      name: "phone",
      value: phoneNumber || "", // undefined break the submission, but empty string does not overwrite the property
    },
  ];

  if (isOwner) {
    fields.push({
      objectTypeId: "0-1",
      name: "admin_user",
      value: HubspotUserStatus.Creator,
    });

    fields.push({
      objectTypeId: "0-1",
      name: "account_owner",
      value: HubspotAccountOwner.Admin,
    });
  }

  const path = window.location.pathname;
  const pageUri = path.replace(/^\/(en-CA|en-US|fr-CA|en-ES)\//, "/");

  const response = await fetch(
    "https://api.hsforms.com/submissions/v3/integration/submit/2614165/32cd819d-07d0-4d26-9edd-68633975ce66",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fields,
        context: {
          hutk: getCookieByName("hubspotutk"), // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
          pageUri,
          pageName,
          ipAddress: ip,
        },
      }),
    }
  );
  const data: Record<string, unknown> = await response.json();

  // eslint-disable-next-line no-console
  console.log(data, `cookie ${getCookieByName("hubspotutk")}`);
};
