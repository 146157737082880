import { isDevelopment, isTest } from "../constants/env";

export const getCookieByName = (name: string) => {
  const cookies = document.cookie.split("; ");
  return cookies.find((cookie) => cookie.includes(name))?.split(`${name}=`)?.[1];
};

export const applyCFAuthHeader = () => {
  //We fake the cf authorization token in test environment to help debug
  const cfCookie = isDevelopment || isTest ? "test_cookie" : getCookieByName("CF_Authorization");
  return {
    headers: {
      ...(cfCookie && { authorization: cfCookie }),
    },
  };
};
