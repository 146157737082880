import React, { KeyboardEvent, memo } from "react";

import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTranslate } from "../../hooks/useTranslate";
import { ContactSupportLink } from "../ContactSupportLink";
import { Button } from "../design-system/Button";
import { TextField } from "../design-system/TextField";
import { Tooltip } from "../design-system/Tooltip";

const PREFIX = "TWO_FACTOR_AUTH_BY_SMS_CODE";

const classes = {
  codeTextField: `${PREFIX}-codeTextField`,
};

const Root = memo(
  styled("div")(() => ({
    [`&& .${classes.codeTextField}`]: {
      width: "100%",
    },
  }))
);

type SMSCodeStepProps = {
  description: React.ReactNode;
  code: string;
  setCode: (code: string) => void;
  submitButtonText: string;
  resendButtonText: string;
  isDisabledSubmitCodeButton: boolean;
  isLoadingSubmitCode: boolean;
  isLoadingResendSms: boolean;
  isResendButtonDisabled: boolean;
  handleResendCode: () => void;
  onSubmitCode?: () => void;
  isHiddenActionButtonsOnMobile?: boolean;
};

export const SMSCodeStep: React.FunctionComponent<React.PropsWithChildren<SMSCodeStepProps>> = ({
  description,
  code,
  setCode,
  submitButtonText,
  resendButtonText,
  isDisabledSubmitCodeButton,
  isLoadingSubmitCode,
  isLoadingResendSms,
  handleResendCode,
  isResendButtonDisabled,
  onSubmitCode,
  isHiddenActionButtonsOnMobile = true,
}) => {
  const { t } = useTranslate();
  const { isPhoneScreen } = useMediaQuery();

  const submitOnKeyDown = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSubmitCode?.();
    }
  };

  return (
    <Root>
      <Grid container spacing={3}>
        <Grid item container spacing={2}>
          <Grid item>{description}</Grid>

          <Grid item sx={{ width: "100%" }}>
            <TextField
              data-test="code-field"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              classes={{ input: classes.codeTextField }}
              onKeyDown={submitOnKeyDown}
            />
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          {((isPhoneScreen && !isHiddenActionButtonsOnMobile) || !isPhoneScreen) && (
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <Button
                  data-test="submit-code-button"
                  vibe="brand"
                  variant="filled"
                  fullWidth
                  type="submit"
                  onClick={onSubmitCode}
                  isLoading={isLoadingSubmitCode}
                  disabled={isDisabledSubmitCodeButton}
                >
                  {submitButtonText}
                </Button>
              </Grid>
              <Tooltip
                title={
                  isResendButtonDisabled
                    ? t("dashboard", "settings.twoFactorAuthBySmsModal.setup.code.resend.tooltip")
                    : ""
                }
                arrow
                placement="bottom"
              >
                <Grid item>
                  <Button
                    onClick={handleResendCode}
                    vibe="brand"
                    variant="outlined"
                    fullWidth
                    isLoading={isLoadingResendSms}
                    disabled={isResendButtonDisabled}
                  >
                    <span>{resendButtonText}</span>
                  </Button>
                </Grid>
              </Tooltip>
            </Grid>
          )}

          <Grid item container justifyContent="center">
            <Grid item>
              <Typography variant="body2" marginRight={0.5}>
                {t("dashboard", "settings.twoFactorAuthBySmsModal.setup.code.help")}
              </Typography>
            </Grid>
            <Grid item>
              <ContactSupportLink />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
};
