import { useEffect, memo } from "react";

import { UserRoles } from "@simplyk/common";
import { useRouter } from "next/router";

import { useCurrentUserContext } from "../../contexts/CurrentUserContext";
import { useApplicationInitialStatus } from "../../hooks/useApplicationInitialStatus";
import { useSimplifiedOnboardingChecklist } from "../../hooks/useSimplifiedOnboardingChecklist";
import { useViewProvider } from "../../hooks/useViewProvider";
import { OrganizationRoutes } from "../../routes/routes";
import { PublicRoute, PublicRouteProps } from "../Routes/PublicRoute";

import { View } from "@/enums/view";
import { DefaultDonorRoute, DefaultOrganizationRoute, setAuthAndRedirectToApp } from "@/helpers/auth";
import { useCanny } from "@/hooks/useCanny";
import { NO_DASHBOARD_REDIRECTION_QUERY_PARAM, useRedirection } from "@/hooks/useRedirection";
import { useView } from "@/hooks/useView";

export type RedirectAuthenticatedPublicRouteProps = PublicRouteProps;

const RedirectAuthenticatedPublicRouteToMemoized: React.FunctionComponent<
  React.PropsWithChildren<RedirectAuthenticatedPublicRouteProps>
> = (props) => {
  const router = useRouter();
  const noDashboardRedirection = router.query[NO_DASHBOARD_REDIRECTION_QUERY_PARAM];

  const { isAuthenticated, userRole } = useCurrentUserContext();
  const { isHomePageAccessible } = useSimplifiedOnboardingChecklist();
  const defaultOrganizationRoute = isHomePageAccessible ? DefaultOrganizationRoute : OrganizationRoutes.FormTable;

  const { view } = useView();
  const { redirectToCanny } = useCanny();
  useApplicationInitialStatus();
  useViewProvider();
  const { isLoading } = useRedirection();

  const { redirection: redirectionUrl, canny } = router.query as {
    redirection: string | null;
    accessToken: string | null;
    canny: "true" | null;
  };

  useEffect(() => {
    // this is to avoid redirections in dev mode
    if (process.env.NEXT_PUBLIC_DISABLE_REDIRECTION === "true") {
      return;
    }
    if (isAuthenticated) {
      if (canny === "true") {
        redirectToCanny();
        return;
      }
      if (redirectionUrl) {
        setAuthAndRedirectToApp({ role: undefined, redirectionUrl });
        return;
      }
      if (!isLoading && !noDashboardRedirection) {
        if (userRole === UserRoles.Owner || userRole === UserRoles.Member || view === View.Organization) {
          router.push(defaultOrganizationRoute);
          return;
        }
        if (view === View.Donor || userRole === UserRoles.Donor) {
          router.push(DefaultDonorRoute);
          return;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return <PublicRoute {...props} />;
};

export const RedirectIfAuthenticatedOnPublicRoute = memo(RedirectAuthenticatedPublicRouteToMemoized);
