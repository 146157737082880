import { memo, useCallback, useState } from "react";

import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import classNames from "classnames";
import { useSnackbar } from "notistack";

import { Button } from "../../components/design-system/Button";
import { TextField } from "../../components/design-system/TextField";
import { Typography } from "../../components/design-system/Typography";
import { AmplitudeEvents } from "../../constants/amplitude";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { RecaptchaAction } from "../../enums/recaptcha";
import { useSendResetPasswordRequestMutation } from "../../gql/queries/generated/authQuery";
import { isEmail, isRequired } from "../../helpers/validators";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { useRecaptchaRetry } from "../../hooks/useRecaptchaRetry";
import { useTranslate } from "../../hooks/useTranslate";

const PREFIX = "ResetPassword";

const classes = {
  alignText: `${PREFIX}-alignText`,
  link: `${PREFIX}-link`,
  resetPasswordCaption: `${PREFIX}-resetPasswordCaption`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledGrid = memo(
  styled(Grid)({
    [`&& .${classes.alignText}`]: {
      textAlign: "center",
    },
    [`&& .${classes.link}`]: {
      cursor: "pointer",
    },
    [`&& .${classes.resetPasswordCaption}`]: {
      lineHeight: "16px",
    },
  })
);

interface ResetPasswordProps {
  defaultEmail: string;
  redirectToLogin: () => void;
}

enum ResetPasswordStep {
  SendResetPassword = "SendResetPassword",
  PasswordSent = "PasswordSent",
  PasswordSentAgain = "PasswordSentAgain",
}

const ResetPassword = ({ defaultEmail, redirectToLogin }: ResetPasswordProps) => {
  const { t } = useTranslate();
  const { logAmplitudeEvent } = useAmplitude();
  const { enqueueSnackbar } = useSnackbar();
  const { locale } = useLocaleContext();

  const [isLoading, setIsLoading] = useState(false);

  const [step, setStep] = useState<ResetPasswordStep>(ResetPasswordStep.SendResetPassword);
  const { execute } = useRecaptchaRetry();
  const [execSendResetPasswordRequest] = useSendResetPasswordRequestMutation();

  const [email, setEmail] = useState<string>(defaultEmail);

  const handleChangeEmail = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmail(e.target.value);
  }, []);

  const submitResetPassword = useCallback(async () => {
    if (!email) {
      return;
    }
    if (step === ResetPasswordStep.SendResetPassword) {
      logAmplitudeEvent(AmplitudeEvents.SignInSendResetPasswordRequest);
    } else {
      logAmplitudeEvent(AmplitudeEvents.SignInSendResetPasswordRequestAgain);
    }
    setIsLoading(true);
    const nextStep =
      step === ResetPasswordStep.SendResetPassword
        ? ResetPasswordStep.PasswordSent
        : ResetPasswordStep.PasswordSentAgain;

    const action = RecaptchaAction.SendResetPasswordRequest;
    const { data } = await execute(action, email, execSendResetPasswordRequest, {
      variables: { sendResetPasswordRequestInput: { email, locale } },
    });
    if (data?.sendResetPasswordRequest?.object?.user) {
      setStep(nextStep);
    } else {
      enqueueSnackbar(t("dashboard", "signin.emailDoesNotExist"), {
        vibe: "warning",
      });
    }
    setIsLoading(false);
  }, [email, enqueueSnackbar, execSendResetPasswordRequest, execute, locale, logAmplitudeEvent, step, t]);

  const handleRedirectToLogin = useCallback(() => {
    if (step === ResetPasswordStep.SendResetPassword) {
      logAmplitudeEvent(AmplitudeEvents.LoginClickLoginResetPassword);
    }

    if (step === ResetPasswordStep.PasswordSent) {
      logAmplitudeEvent(AmplitudeEvents.LoginClickLoginCheckEmail);
    }

    if (step === ResetPasswordStep.PasswordSentAgain) {
      logAmplitudeEvent(AmplitudeEvents.LoginClickLoginCheckEmail2);
    }
    redirectToLogin();
  }, [logAmplitudeEvent, redirectToLogin, step]);

  return (
    <>
      {step === ResetPasswordStep.SendResetPassword && (
        <StyledGrid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="h4"> {t("dashboard", "signin.resetPassword")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" className={classes.resetPasswordCaption}>
                      {t("dashboard", "signin.resetPasswordCaption")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  data-test="email-input"
                  id="email"
                  label={t("dashboard", "signin.email")}
                  value={email}
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={handleChangeEmail}
                  rules={[
                    isRequired(t("dashboard", "signin.emailIsRequired")),
                    isEmail(t("dashboard", "signin.emailFormat"), t("dashboard", "common.emailFormatWrongChar")),
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  data-test="submit-reset-password-button"
                  type="submit"
                  fullWidth
                  variant="filled"
                  vibe="brand"
                  isLoading={isLoading}
                  onClick={submitResetPassword}
                >
                  {t("dashboard", "signin.resetPassword")}
                </Button>
              </Grid>
              <Grid item xs={12} className={classNames(classes.link, classes.alignText)}>
                <Link onClick={handleRedirectToLogin} underline="hover">
                  <Typography variant="caption" vibe="primary-main">
                    {t("dashboard", "signin.reconnect")}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </StyledGrid>
      )}
      {(step === ResetPasswordStep.PasswordSent || step === ResetPasswordStep.PasswordSentAgain) && (
        <StyledGrid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="h4" data-test="check-email-title">
                      {t("dashboard", "signin.checkYourEmail")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" className={classes.resetPasswordCaption}>
                      {t("dashboard", "signin.checkYourEmailCaption1")}
                    </Typography>
                    <Typography variant="caption" className={classes.resetPasswordCaption} vibe="primary-main">
                      {email}
                    </Typography>
                    {step === ResetPasswordStep.PasswordSent && (
                      <Typography variant="caption" className={classes.resetPasswordCaption}>
                        {t("dashboard", "signin.checkYourEmailCaption2")}
                      </Typography>
                    )}
                    {step === ResetPasswordStep.PasswordSentAgain && (
                      <Typography variant="caption" className={classes.resetPasswordCaption}>
                        {t("dashboard", "signin.checkYourEmailCaption3")}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  data-test="redirect-to-login-button"
                  fullWidth
                  variant="filled"
                  vibe="brand"
                  onClick={handleRedirectToLogin}
                >
                  {t("dashboard", "signin.backToLogin")}
                </Button>
              </Grid>
              {step === ResetPasswordStep.PasswordSent && (
                <Grid item xs={12}>
                  <Button
                    data-test="reset-password-button"
                    fullWidth
                    variant="outlined"
                    vibe="brand"
                    isLoading={isLoading}
                    onClick={submitResetPassword}
                  >
                    {t("dashboard", "signin.resendLink")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </StyledGrid>
      )}
    </>
  );
};

export default ResetPassword;
