import { ReferralQuestionChoice, getOrganizationCountryFromCountryRegionData } from "@simplyk/common";

import { EditPasswordData } from "../components/Settings/Modals/EditPasswordModal/types";
import { CheckIdResetPasswordIsValid } from "../dashboard-pages/ResetOrCreatePassword/types";
import { SignInData } from "../dashboard-pages/SignIn/types";
import { SignUpData } from "../features/Register/types";
import { Locales, SignInFrontendInput, SignUpInput } from "../gql/gql-types";

export interface SignUpParams {
  source?: string;
  fundraisingId?: string;
  goBackUrl?: string;
  referredByOrganizationId?: string;
  isSmallScreen?: boolean;
}

export const formatSignInData = (data: SignInData, locale: Locales, organizationId?: string): SignInFrontendInput => {
  return {
    email: data.email.toLowerCase(),
    password: data.password,
    locale,
    organizationId,
    twoFactorCode: data.twoFactorCode,
    smsCode: data.smsCode,
  };
};

export const formatSignUpData = (data: SignUpData, locale: Locales, params: SignUpParams): SignUpInput => {
  const simplykSource =
    params.source === "capterra"
      ? ReferralQuestionChoice.Capterra
      : data.isNbcTcReferral === "yes"
        ? ReferralQuestionChoice.Nbctc
        : null;

  const country = getOrganizationCountryFromCountryRegionData(data.organismCountry);
  return {
    email: data.email.toLowerCase(),
    organizationName: data.nonprofit?.name || data.organizationName,
    charityNumber: data.nonprofit?.einBn || data.charityNumber || undefined,
    firstName: data.firstName,
    lastName: data.lastName,
    password: data.password || "",
    locale,
    country: data.nonprofit?.country || country,
    source: simplykSource,
    referralQuestionOther: data.nbctcReferralCode,
    websiteUrl: data.nonprofit ? null : data.websiteUrl?.replace(/ /g, "").toLowerCase(), // trim the website (stripe verification does not allow white spaces)
    referredByOrganizationId: params.referredByOrganizationId,
    isMobile: params.isSmallScreen ? true : false,
    isNonprofitSearchSuccessful: data.nonprofit ? true : false,
    googleToken: data.googleToken,
    type: data.type,
    ssoStytchData: data.ssoStytchData,
  };
};

interface FormatResetPasswordData {
  email: string;
  resetPasswordToken: string;
  newPassword: string;
  verifyEmailToken: string;
}
export const formatResetPasswordData = (data: FormatResetPasswordData): FormatResetPasswordData => {
  return {
    email: data.email.toLowerCase(),
    newPassword: data.newPassword,
    resetPasswordToken: decodeURIComponent(data.resetPasswordToken), // URI is encoded in auth.service, before email is sent
    verifyEmailToken: decodeURIComponent(data.verifyEmailToken), // URI is encoded in auth.service, before email is sent
  };
};

export const formatCheckIfResetPasswordIsValid = (data: {
  email: string;
  resetPasswordToken: string;
}): CheckIdResetPasswordIsValid => {
  return {
    email: data.email.toLowerCase(),
    resetPasswordToken: decodeURIComponent(data.resetPasswordToken), // URI is encoded in auth.service, before email is sent
  };
};

export const formatEditPasswordData = (data: EditPasswordData): EditPasswordData => {
  return {
    oldPassword: data.oldPassword,
    newPassword: data.newPassword,
  };
};
