import { useEffect, useState } from "react";

import { AmplitudeFlagKey } from "@simplyk/common";

import { useCurrentUserContext } from "../contexts/CurrentUserContext";
import { useStripeAccountContext } from "../contexts/StripeAccountContext";

import { useAmplitude } from "./amplitude/useAmplitude";

export const useSimplifiedOnboardingChecklist = () => {
  const { getExperimentValue } = useAmplitude();
  const { organization, userIsLoading } = useCurrentUserContext();
  const { hasAtLeastOnePayout, stripeCustomBalance } = useStripeAccountContext();

  const [simplifiedOnboardingChecklist, setSimplifiedOnboardingChecklist] = useState(false);
  const [isSimplifiedOnboardingChecklistLoading, setIsSimplifiedOnboardingChecklistLoading] = useState(true);

  useEffect(() => {
    const fetchSimplifiedOnboardingChecklist = async () => {
      setIsSimplifiedOnboardingChecklistLoading(true);
      const simplifiedOnboardingChecklist = await getExperimentValue({
        flagKey: AmplitudeFlagKey.SimplifiedOnboardingChecklist,
        organizationId: organization?.id,
        userProperties: { organizationId: organization?.id || "" },
      });

      setSimplifiedOnboardingChecklist(simplifiedOnboardingChecklist?.value === "b");
      setIsSimplifiedOnboardingChecklistLoading(false);
    };
    fetchSimplifiedOnboardingChecklist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  const isNpoStarter = Boolean(organization?.hubspotNpoStarterDealId);

  const shouldDisplayOnboardingSteps =
    simplifiedOnboardingChecklist &&
    !organization?.dismissOnboardingOnFormPageAt &&
    !hasAtLeastOnePayout &&
    !userIsLoading &&
    !isNpoStarter;

  const isHomePageAccessible =
    !isSimplifiedOnboardingChecklistLoading &&
    (!simplifiedOnboardingChecklist || (stripeCustomBalance || 0) > 0 || hasAtLeastOnePayout || isNpoStarter);

  return {
    simplifiedOnboardingChecklist,
    isSimplifiedOnboardingChecklistLoading,
    shouldDisplayOnboardingSteps,
    isHomePageAccessible,
  };
};
