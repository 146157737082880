import { memo, useCallback, useState } from "react";

import { Box, Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { DashboardRoutes } from "@simplyk/common";
import { useRouter } from "next/router";
import { useForm, useWatch } from "react-hook-form";

import { Button } from "../../components/design-system/Button";
import { Divider } from "../../components/design-system/Divider/Divider";
import { Infobox } from "../../components/design-system/Infobox";
import { TextField } from "../../components/design-system/TextField";
import { Typography } from "../../components/design-system/Typography";
import { AmplitudeEvents } from "../../constants/amplitude";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { RecaptchaAction } from "../../enums/recaptcha";
import { SignInFrontendInput, UserRegistrationStatus } from "../../gql/gql-types";
import { SignInMutation, useIsUserEmailRegisteredMutation } from "../../gql/queries/generated/authQuery";
import { submitHubspotForm } from "../../helpers/hubspot";
import { captureSentryError } from "../../helpers/sentry";
import { isEmailAndAllowPlus, isRequired } from "../../helpers/validators";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { useRecaptchaRetry } from "../../hooks/useRecaptchaRetry";
import { useTranslate } from "../../hooks/useTranslate";

import { SignInData } from "./types";
import { SignInStep } from "./useSignIn";

import { StytchGoogleButton } from "@/components/StytchGoogleButton/StytchGoogleButton";
import { getGoogleStytchUrl } from "@/helpers/stytch";

const PREFIX = "CheckEmail";

const classes = {
  form: `${PREFIX}-form`,
  relative: `${PREFIX}-relative`,
  orText: `${PREFIX}-orText`,
  orGoogleText: `${PREFIX}-orGoogleText`,
  alignText: `${PREFIX}-alignText`,
};

const Root = memo(
  styled("form")(({ theme }: { theme: Theme }) => ({
    [`&&.${classes.form}`]: {
      width: "100%",
      marginTop: theme.spacing(2),
    },

    [`&& .${classes.relative}`]: { position: "relative" },

    [`&& .${classes.orText}`]: {
      position: "absolute",
      top: 0,
      left: `calc(50% - ${theme.spacing(1.5)})`,
      backgroundColor: "white",
      padding: theme.spacing(2),
    },

    [`&& .${classes.orGoogleText}`]: {
      color: theme.palette.text.form.quiet,
    },

    [`&& .${classes.alignText}`]: {
      textAlign: "center",
    },
  }))
);

interface CheckEmailProps {
  setSignInStep: (step: SignInStep) => void;
  handleChangeEmail: (email: string) => void;
  email: string;
  signin: (signInInput: SignInFrontendInput) => Promise<void | SignInMutation | null>;
}

export const CheckEmail = ({ setSignInStep, email, handleChangeEmail }: CheckEmailProps) => {
  const { t } = useTranslate();
  const router = useRouter();

  const { logAmplitudeEvent, logAmplitudeEventCallback } = useAmplitude();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSSO, setIsLoadingSSO] = useState<boolean>(false);
  const { locale } = useLocaleContext();
  const [execIsEmailRegistered] = useIsUserEmailRegisteredMutation();
  const { redirectionUrl } = router.query as {
    canny: "true" | null;
    redirectionUrl: string | null;
  };
  const [displayCreateAccount, setDisplayCreateAccount] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { execute } = useRecaptchaRetry(() => {
    setIsLoading(false);
    form.setError("email", { message: t("dashboard", "signin.recaptchaErrorOnIsEmailRegistered") });
  });

  const form = useForm<SignInData>({
    reValidateMode: "onChange",
    defaultValues: { email },
    shouldUnregister: true,
  });

  const watchedEmail = useWatch({ control: form.control, name: "email" });

  const onSubmit = form.handleSubmit(async (data: SignInData) => {
    setErrorMessage(null);
    setIsLoading(true);
    setIsLoadingSSO(false);
    handleChangeEmail(data.email);

    form.setValue("email", data.email); // Avoid Google Password to edit email, cause formValue is registered after a direct click on an autocomplete proposition
    submitHubspotForm({ ...data, pageName: "Sign In" });

    const action = RecaptchaAction.IsEmailRegistered;
    const email = data.email;
    const { data: isEmailRegistered, errors } = await execute(action, email, execIsEmailRegistered, {
      variables: {
        isEmailRegisteredInput: { email: data.email, locale, redirectionUrl },
      },
    });

    if (isEmailRegistered?.isUserEmailRegistered.error || !isEmailRegistered?.isUserEmailRegistered.object || errors) {
      captureSentryError({
        message: "Recaptcha error on isUserEmailRegistered",
        params: { errors, email: data.email } || {
          ...isEmailRegistered?.isUserEmailRegistered.error,
          email: data.email,
        },
      });

      setIsLoading(false);
      form.setError("email", { message: t("dashboard", "signin.recaptchaErrorOnIsEmailRegistered") });
      return;
    }

    const regex = /\+/;
    const isEmailAndAllowPlus = regex.test(email);

    const registrationStatus = isEmailRegistered?.isUserEmailRegistered.object.userRegistrationStatus;

    if (registrationStatus === UserRegistrationStatus.Unknown && isEmailAndAllowPlus) {
      form.setError("email", { message: t("dashboard", "common.emailFormatWrongChar") });
      setIsLoading(false);
      return;
    }

    if (registrationStatus === UserRegistrationStatus.Unknown) {
      setDisplayCreateAccount(true);
      setIsLoading(false);
      form.setError("email", { message: t("dashboard", "signin.userNotFound") });
      return;
    }

    const hasPassword = isEmailRegistered?.isUserEmailRegistered.object.hasPassword;

    if (isEmailRegistered.isUserEmailRegistered.object.shouldAuthenticateWithGoogle) {
      signinWithGoogleCallback();
    } else if (registrationStatus === UserRegistrationStatus.Registered) {
      if (!hasPassword) {
        setSignInStep(SignInStep.DonorLogin);
      } else {
        setSignInStep(SignInStep.EnterPassword);
      }
    }

    setIsLoading(false);
  });

  const handleClickOnEmail = useCallback(() => {
    if (form.formState.errors["email"]?.message) {
      logAmplitudeEvent(AmplitudeEvents.LoginFocusWrongEmailEmail);
    } else {
      logAmplitudeEvent(AmplitudeEvents.LoginFocusEmail);
    }
  }, [form.formState.errors, logAmplitudeEvent]);

  const createAccountEvent = useCallback(() => {
    logAmplitudeEvent(AmplitudeEvents.SignInPageSwitchToSignUp);
  }, [logAmplitudeEvent]);

  const signinWithGoogleCallback = useCallback(async () => {
    logAmplitudeEvent(AmplitudeEvents.UserSignInSSOStarted, {
      sso_type: "Google",
    });

    await router.push(getGoogleStytchUrl());
  }, [logAmplitudeEvent, router]);

  return (
    <Root className={classes.form} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    {displayCreateAccount
                      ? t("dashboard", "signin.connectionOrInscription")
                      : t("dashboard", `signin.loginToZeffy`)}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="caption">{t("dashboard", "signin.zeffyTheOnlyFree")}</Typography>
                </Grid>
              </Grid>
            </Grid>

            {errorMessage && (
              <Grid item xs={12} marginTop={1}>
                <Infobox vibe="danger">{errorMessage}</Infobox>
              </Grid>
            )}
            <Grid item xs={12}>
              <StytchGoogleButton label="signin" />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Divider sx={{ flexGrow: 1 }} />
                <Box mx={2} className={classes.orGoogleText}>
                  {t("common", "or")}
                </Box>
                <Divider sx={{ flexGrow: 1 }} />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <TextField
                data-test="email-input"
                id="email"
                label={t("dashboard", "signin.email")}
                name="email"
                autoComplete="email"
                autoFocus
                control={form.control}
                errorMessage={form.formState.errors["email"]?.message}
                rules={[
                  isRequired(t("dashboard", "signin.emailIsRequired")),
                  isEmailAndAllowPlus(
                    t("dashboard", "signin.emailFormat"),
                    t("dashboard", "common.emailFormatWrongChar")
                  ),
                ]}
                onClick={handleClickOnEmail}
                onBlur={logAmplitudeEventCallback(AmplitudeEvents.UserSignInInputChanged, {
                  input_name: "email",
                  valid_field: !form.formState.errors["email"],
                  field_empty: !form.getValues("email"),
                })}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Button
            data-test="submit-email-button"
            type="submit"
            fullWidth
            variant="filled"
            vibe="brand"
            isLoading={isLoading}
            disabled={isLoadingSSO || !watchedEmail}
          >
            {t("common", "next")}
          </Button>
        </Grid>

        {(displayCreateAccount || errorMessage) && (
          <>
            <Grid item xs={12} className={classes.relative}>
              <Divider />
              <Typography variant="caption" className={classes.orText}>
                {t("common", "or")}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.alignText}>
              <Button
                data-test="organism-inscription-button"
                href={`${DashboardRoutes.SignUp}?email=${watchedEmail}`}
                variant="outlined"
                vibe="brand"
                fullWidth
                onClick={createAccountEvent}
              >
                {t("dashboard", "signin.organismInscription")}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Root>
  );
};
