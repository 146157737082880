import { useEffect, useRef, useState, type JSX } from "react";

import { Box, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Emptyable } from "@simplyk/common";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";

import { Typography } from "../../components/design-system/Typography";
import { SMSCodeStep } from "../../components/SMSCodeStep/SMSCodeStep";
import { useResendCode } from "../../components/SMSCodeStep/useResendCode";
import { AmplitudeEvents } from "../../constants/amplitude";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { SignInFrontendInput } from "../../gql/gql-types";
import { SignInMutation } from "../../gql/queries/generated/authQuery";
import { formatSignInData } from "../../helpers/authFormat";
import { submitHubspotForm } from "../../helpers/hubspot";
import { getLastUserView } from "../../helpers/localStorage";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTranslate } from "../../hooks/useTranslate";

import { SignInData } from "./types";

interface EnterSMSCodeProps {
  email: string;
  password: string;
  maskedPhoneNumber: string;
  loginBackButton: JSX.Element;
  signin: (signInInput: SignInFrontendInput) => Promise<void | SignInMutation | null>;
  stytchVerdict?: Emptyable<string>;
}

export const EnterSMSCode = ({
  email,
  password,
  maskedPhoneNumber,
  loginBackButton,
  signin,
  stytchVerdict,
}: EnterSMSCodeProps) => {
  const { t } = useTranslate();
  const { isPhoneScreen } = useMediaQuery();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState("");
  const { logAmplitudeEvent } = useAmplitude();
  const { locale } = useLocaleContext();
  const theme = useTheme();

  const currentUserId = useRef<string | null>(null);

  const {
    isResendButtonDisabled,
    resendButtonCountdownText,
    resendCode,
    isLoadingSendSms: isLoadingResendSms,
  } = useResendCode({ email });

  const form = useForm<SignInData>({
    reValidateMode: "onChange",
    shouldUnregister: true,
  });

  const onSubmit = form.handleSubmit(async (data: SignInData) => {
    setIsLoading(true);
    submitHubspotForm({ ...data, pageName: "Sign In" });

    const lastUserView = currentUserId.current ? getLastUserView(currentUserId.current) : undefined;

    const signInResponse = await signin(
      formatSignInData({ ...data, email, password, smsCode: code }, locale, lastUserView?.organizationId)
    );
    if (!signInResponse?.signIn?.object) {
      if (signInResponse?.signIn.error?.code === "authenticate_sms_otp_code_incorrect") {
        enqueueSnackbar(t("dashboard", "stytch2FA.error.wrongCode"), {
          vibe: "danger",
        });
      } else {
        enqueueSnackbar(t("dashboard", "signin.unknownError"), {
          vibe: "danger",
        });
      }
      setIsLoading(false);
    }
  });

  /**
   * Log the page view event
   */
  useEffect(() => {
    logAmplitudeEvent(AmplitudeEvents.TwoFAPrompted, { moment: "login", stytch_recommendation: stytchVerdict });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isPhoneScreen && <Grid marginLeft={-2}>{loginBackButton}</Grid>}
      <form onSubmit={onSubmit}>
        <Stack spacing={2}>
          <Typography variant="h4">{t("dashboard", `signin.smsCode.title`)}</Typography>
          <SMSCodeStep
            description={
              <Typography variant="caption">
                {t("dashboard", "signin.smsCode.body.part1")}
                <Box component="span" sx={{ fontWeight: theme.typography.fontWeightBold }}>
                  {maskedPhoneNumber}
                </Box>
                {t("dashboard", "signin.smsCode.body.part2")}
              </Typography>
            }
            code={code}
            setCode={setCode}
            submitButtonText={t("common", "Submit")}
            resendButtonText={resendButtonCountdownText}
            isDisabledSubmitCodeButton={code.length !== 6}
            isLoadingResendSms={isLoadingResendSms}
            isResendButtonDisabled={isResendButtonDisabled}
            handleResendCode={resendCode}
            isLoadingSubmitCode={isLoading}
            onSubmitCode={onSubmit}
            isHiddenActionButtonsOnMobile={false}
          />
        </Stack>
      </form>
    </>
  );
};
