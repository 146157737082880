import { memo, useCallback, useState } from "react";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";

import { Button } from "../../components/design-system/Button";
import { Typography } from "../../components/design-system/Typography";
import { AmplitudeEvents } from "../../constants/amplitude";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { RecaptchaAction } from "../../enums/recaptcha";
import { useSendResetPasswordRequestMutation } from "../../gql/queries/generated/authQuery";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { useLogVisitPageAmplitudeEvent } from "../../hooks/amplitude/useLogVisitPageAmplitudeEvent";
import { useRecaptchaRetry } from "../../hooks/useRecaptchaRetry";
import { useTranslate } from "../../hooks/useTranslate";

const PREFIX = "DonorLogin";

const classes = {
  resetPasswordCaption: `${PREFIX}-resetPasswordCaption`,
};

const StyledGrid = memo(
  styled(Grid)({
    [`&& .${classes.resetPasswordCaption}`]: {
      lineHeight: "16px",
    },
  })
);

interface DonorLoginProps {
  defaultEmail: string;
  redirectToLogin: () => void;
}

const DonorLogin = ({ defaultEmail, redirectToLogin }: DonorLoginProps) => {
  const { t } = useTranslate();
  const { logAmplitudeEvent } = useAmplitude();
  const { query, pathname } = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { locale, isoLocale } = useLocaleContext();

  const [isLoading, setIsLoading] = useState(false);

  useLogVisitPageAmplitudeEvent({
    payload: { donor: true, path: pathname, locale, isoLocale, ...query },
  });

  const { execute } = useRecaptchaRetry();
  const [execSendResetPasswordRequest] = useSendResetPasswordRequestMutation();

  const submitResetPassword = useCallback(async () => {
    if (!defaultEmail) {
      return;
    }
    setIsLoading(true);
    logAmplitudeEvent(AmplitudeEvents.LoginDonorClickResendLink);

    const action = RecaptchaAction.SendResetPasswordRequest;
    const { data } = await execute(action, defaultEmail, execSendResetPasswordRequest, {
      variables: { sendResetPasswordRequestInput: { email: defaultEmail, locale } },
    });
    if (data?.sendResetPasswordRequest?.object?.user) {
      enqueueSnackbar(t("dashboard", "signin.emailResent", { email: defaultEmail }), { vibe: "positive" });
    } else {
      enqueueSnackbar(t("dashboard", "signin.emailDoesNotExist"), { vibe: "warning" });
    }
    setIsLoading(false);
  }, [defaultEmail, enqueueSnackbar, execSendResetPasswordRequest, execute, locale, logAmplitudeEvent, t]);

  const handleRedirectToLogin = useCallback(() => {
    logAmplitudeEvent(AmplitudeEvents.LoginDonorClickBackToLogin);
    redirectToLogin();
  }, [logAmplitudeEvent, redirectToLogin]);

  return (
    <StyledGrid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography variant="h4"> {t("dashboard", "signin.checkYourEmailToCreatePassword")}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" className={classes.resetPasswordCaption}>
                  {t("dashboard", "signin.donor.checkYourEmailCaption1")}
                </Typography>
                <Typography variant="caption" className={classes.resetPasswordCaption} vibe="primary-main">
                  {defaultEmail}
                </Typography>
                <Typography variant="caption" className={classes.resetPasswordCaption}>
                  {t("dashboard", "signin.donor.checkYourEmailCaption2")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              data-test="redirect-to-login-button"
              fullWidth
              variant="filled"
              vibe="brand"
              onClick={handleRedirectToLogin}
            >
              {t("dashboard", "signin.backToLogin")}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              data-test="resend-link-button"
              fullWidth
              variant="outlined"
              vibe="brand"
              isLoading={isLoading}
              onClick={submitResetPassword}
            >
              {t("dashboard", "signin.resendLink")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </StyledGrid>
  );
};

export default DonorLogin;
