import { useCallback, useEffect, useState } from "react";

import { Emptyable } from "@simplyk/common/lib/helpers/utils";
import { useSnackbar } from "notistack";

import { RecaptchaAction } from "../../enums/recaptcha";
import { trpc } from "../../helpers/trpc";
import { useRecaptchaRetry } from "../../hooks/useRecaptchaRetry";
import { useTranslate } from "../../hooks/useTranslate";
import { ERROR_KEY } from "../Settings/Modals/TwoFactorAuthBySmsModal/constants";

const DEFAULT_RESEND_COOLDOWN = 120;

type UseResendCodeProps =
  | {
      phoneNumber?: Emptyable<string>;
      email?: never;
    }
  | {
      phoneNumber?: never;
      email: string;
    };

export const useResendCode = ({ phoneNumber, email }: UseResendCodeProps) => {
  const { t } = useTranslate();
  const { enqueueSnackbar } = useSnackbar();
  const { executeTrpc } = useRecaptchaRetry();

  const { mutateAsync: sendSmsOtp, isLoading: isLoadingSendSms } = trpc.sendSmsOtp.useMutation();
  const { mutateAsync: sendSmsOtpToSignin, isLoading: isLoadingSendSmsToSignin } =
    trpc.sendSmsOtpToSignin.useMutation();

  const [resendCooldown, setResendCooldown] = useState(0);

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const resendButtonText = t("dashboard", "settings.twoFactorAuthBySmsModal.setup.code.resend");

  const isResendButtonDisabled = resendCooldown > 0 || isLoadingSendSms;
  const resendButtonCountdownText = resendCooldown > 0 ? `${resendButtonText} - ${resendCooldown}s` : resendButtonText;

  const triggerCooldown = useCallback(() => {
    setResendCooldown(DEFAULT_RESEND_COOLDOWN);
    const timer = setInterval(() => {
      setResendCooldown((prevCooldown) => (prevCooldown <= 0 ? 0 : prevCooldown - 1));
    }, 1000);
    setTimer(timer);
  }, []);

  const resendCode = useCallback(async () => {
    const mfaPhoneNumber = phoneNumber ? (phoneNumber?.startsWith("+") ? phoneNumber : `+${phoneNumber}`) : null;
    const response = email
      ? await executeTrpc(RecaptchaAction.SendSmsOtpToSignin, email, sendSmsOtpToSignin, {
          email,
        })
      : await executeTrpc(RecaptchaAction.SendSmsOtp, email, sendSmsOtp, {
          mfaPhoneNumber,
        });
    if (response.error) {
      enqueueSnackbar(t("dashboard", ERROR_KEY), { vibe: "danger" });
    } else {
      triggerCooldown();
      enqueueSnackbar(t("dashboard", "settings.twoFactorAuthBySmsModal.setup.code.resend.sent"), { vibe: "positive" });
    }
  }, [email, enqueueSnackbar, executeTrpc, phoneNumber, sendSmsOtp, sendSmsOtpToSignin, t, triggerCooldown]);

  useEffect(() => {
    if (resendCooldown <= 0 && timer) {
      clearTimeout(timer);
    }
  }, [resendCooldown, timer]);

  return {
    isResendButtonDisabled,
    resendButtonCountdownText,
    isLoadingSendSms: isLoadingSendSms || isLoadingSendSmsToSignin,
    resendCode,
  };
};
