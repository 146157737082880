import { FC } from "react";

import { Button } from "@/design-system/Button";
import { getGoogleStytchUrl } from "@/helpers/stytch";
import { useTranslate } from "@/hooks/useTranslate";
import GoogleIcon from "@/icons/illustration/google.svg";

interface StytchSignUpWithGoogleButtonProps {
  label: "signin" | "signup";
}

export const StytchGoogleButton: FC<StytchSignUpWithGoogleButtonProps> = ({ label }) => {
  const { t } = useTranslate();
  const googleStytchUrl = getGoogleStytchUrl();

  return (
    <Button fullWidth variant="outlined" vibe="neutral" size="large" startIcon={<GoogleIcon />} href={googleStytchUrl}>
      {label === "signin" ? t("dashboard", "signin.withGoogle") : t("dashboard", "signup.continueWithGoogle")}
    </Button>
  );
};
