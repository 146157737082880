import { useCallback } from "react";

import { useRouter } from "next/router";

import { useCurrentUserContext } from "../contexts/CurrentUserContext";
import { View } from "../enums/view";
import { DefaultDonorRoute, DefaultOrganizationRoute } from "../helpers/auth";
import { setView as setViewReducer } from "../redux/reducers/view.reducer";

import { useAppDispatch, useAppSelector } from "./redux";

export const useView = () => {
  const router = useRouter();
  const { currentUser } = useCurrentUserContext();

  const currentView = useAppSelector((state) => state.view.view);
  const dispatch = useAppDispatch();

  const setView = useCallback(
    (view: View, currentOrganizationId?: string) => {
      dispatch(
        setViewReducer({
          view,
          currenUserId: currentUser?.id,
          currentOrganizationId: currentOrganizationId || currentUser?.currentOrganization?.id,
        })
      );
    },
    [dispatch, currentUser?.id, currentUser?.currentOrganization?.id]
  );
  const setViewAndRedirect = useCallback(
    (view: View, currentOrganizationId?: string) => {
      const url = view === View.Donor ? DefaultDonorRoute : DefaultOrganizationRoute;
      router.push(url);
      setView(view, currentOrganizationId);
    },
    [router, setView]
  );
  return { view: currentView, setView, setViewAndRedirect };
};
