import * as Types from '../../gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCannyTokenMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCannyTokenMutation = { __typename?: 'Mutation', getCannyToken: { __typename?: 'StringResponseObject', object?: string | null } };


export const GetCannyTokenDocument = gql`
    mutation getCannyToken {
  getCannyToken {
    object
  }
}
    `;
export type GetCannyTokenMutationFn = Apollo.MutationFunction<GetCannyTokenMutation, GetCannyTokenMutationVariables>;

/**
 * __useGetCannyTokenMutation__
 *
 * To run a mutation, you first call `useGetCannyTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetCannyTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getCannyTokenMutation, { data, loading, error }] = useGetCannyTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetCannyTokenMutation(baseOptions?: Apollo.MutationHookOptions<GetCannyTokenMutation, GetCannyTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetCannyTokenMutation, GetCannyTokenMutationVariables>(GetCannyTokenDocument, options);
      }
export type GetCannyTokenMutationHookResult = ReturnType<typeof useGetCannyTokenMutation>;
export type GetCannyTokenMutationResult = Apollo.MutationResult<GetCannyTokenMutation>;
export type GetCannyTokenMutationOptions = Apollo.BaseMutationOptions<GetCannyTokenMutation, GetCannyTokenMutationVariables>;