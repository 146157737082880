import { useCallback } from "react";

import { useRouter } from "next/router";

import { useGetCannyTokenMutation } from "../gql/queries/generated/cannyQuery";

export const useCanny = () => {
  const [execGetCannyToken] = useGetCannyTokenMutation();
  const router = useRouter();
  const { redirect: cannyRedirection, companyID } = router.query as {
    redirect: string | null;
    companyID: string | null;
  };
  const redirectToCanny = useCallback(async () => {
    const { data } = await execGetCannyToken();
    const cannyUrl = `https://canny.io/api/redirects/sso?companyID=${companyID}&ssoToken=${
      data?.getCannyToken.object || ""
    }&redirect=${cannyRedirection}`;

    return router.push(cannyUrl);
  }, [cannyRedirection, companyID, execGetCannyToken, router]);

  return { redirectToCanny };
};
