import { memo, useCallback, useRef, useState, type JSX } from "react";

import Grid from "@mui/material/Grid";
import { styled, Theme } from "@mui/material/styles";
import { useForm } from "react-hook-form";

import { Button } from "../../components/design-system/Button";
import { Link } from "../../components/design-system/Link";
import { TextField } from "../../components/design-system/TextField";
import { Typography } from "../../components/design-system/Typography";
import { AmplitudeEvents } from "../../constants/amplitude";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { SignInFrontendInput } from "../../gql/gql-types";
import { SignInMutation } from "../../gql/queries/generated/authQuery";
import { formatSignInData } from "../../helpers/authFormat";
import { submitHubspotForm } from "../../helpers/hubspot";
import { getLastUserView } from "../../helpers/localStorage";
import { getErrorMessages } from "../../helpers/reactHookForm";
import { isRequired } from "../../helpers/validators";
import { useAmplitude } from "../../hooks/amplitude/useAmplitude";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTranslate } from "../../hooks/useTranslate";

import { SignInData } from "./types";

const PREFIX = "EnterPassword";

const classes = {
  bold: `${PREFIX}-bold`,
  form: `${PREFIX}-form`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledForm = memo(
  styled("form")(({ theme }: { theme: Theme }) => ({
    [`&& .${classes.bold}`]: {
      fontWeight: theme.typography.fontWeightBold,
    },

    [`&&.${classes.form}`]: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
  }))
);

interface EnterPasswordProps {
  email: string;
  loginBackButton: JSX.Element;
  redirectToResetPassword: () => void;
  setPassword: (password: string) => void;
  signin: (signInInput: SignInFrontendInput) => Promise<void | SignInMutation | null>;
}

export const EnterPassword = ({
  email,
  redirectToResetPassword,
  setPassword,
  loginBackButton,
  signin,
}: EnterPasswordProps) => {
  const { t } = useTranslate();
  const { isPhoneScreen } = useMediaQuery();

  const [isLoading, setIsLoading] = useState(false);
  const { logAmplitudeEvent, logAmplitudeEventCallback } = useAmplitude();

  const { locale } = useLocaleContext();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = useCallback(() => {
    logAmplitudeEvent(AmplitudeEvents.LoginClickShowPassword);
    setShowPassword((prev) => !prev);
  }, [logAmplitudeEvent]);

  const currentUserId = useRef<string | null>(null);

  const form = useForm<SignInData>({
    reValidateMode: "onChange",
    defaultValues: { email },
    shouldUnregister: true,
  });

  // We want to send the event even if the form is not valid
  const onClickSubmit = useCallback(async () => {
    await form.trigger();
    const errors = getErrorMessages<SignInData>({
      keys: ["email", "password", "twoFactorCode"],
      errors: form.formState.errors,
    });
    if (errors.length) {
      logAmplitudeEvent(AmplitudeEvents.UserSignInSubmitted, {
        errors,
      });
    }
  }, [form, logAmplitudeEvent]);

  const onSubmit = form.handleSubmit(async (data: SignInData) => {
    setIsLoading(true);
    form.setValue("email", data.email); // Avoid Google Password to edit email, cause formValue is registered after a direct click on an autocomplete proposition
    submitHubspotForm({ ...data, pageName: "Sign In" });

    const lastUserView = currentUserId.current ? getLastUserView(currentUserId.current) : undefined;

    const signInResponse = await signin(formatSignInData({ ...data, email }, locale, lastUserView?.organizationId));

    setPassword(data.password);

    if (!signInResponse?.signIn?.object) {
      if (signInResponse?.signIn.error?.code === "authenticate_on_stytch_member_reset_password") {
        form.setError("password", { message: t("dashboard", "signin.userShouldResetPassword") });
      } else {
        form.setError("password", { message: t("dashboard", "signin.wrongPassword") });
      }
      setIsLoading(false);
    }

    logAmplitudeEvent(AmplitudeEvents.UserSignInSubmitted, {
      errors: getErrorMessages<SignInData>({
        keys: ["email", "password", "twoFactorCode"],
        errors: form.formState.errors,
      }),
    });
  });

  const handleClickOnPassword = useCallback(() => {
    if (form.formState.errors["password"]?.message) {
      logAmplitudeEvent(AmplitudeEvents.LoginFocusPassword2);
    } else {
      logAmplitudeEvent(AmplitudeEvents.LoginFocusPassword);
    }
  }, [form.formState.errors, logAmplitudeEvent]);

  return (
    <>
      {!isPhoneScreen && <Grid marginLeft={-2}>{loginBackButton}</Grid>}
      <StyledForm className={classes.form} onSubmit={onSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="h4">{t("dashboard", `signin.enterPassword.title`)}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {t("dashboard", "signin.enterPassword.body.part1")}
                      <span className={classes.bold}>{email}</span>
                      {t("dashboard", "signin.enterPassword.body.part2")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      data-test="password-input"
                      autoFocus
                      name="password"
                      label={t("dashboard", "signin.password")}
                      id="password"
                      autoComplete="current-password"
                      control={form.control}
                      errorMessage={form.formState.errors["password"]?.message}
                      rules={[isRequired(t("dashboard", "signin.passwordIsRequired") as string)]}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <Link vibe="brand" onClick={togglePasswordVisibility}>
                          {showPassword ? t("common", "hide") : t("common", "show")}
                        </Link>
                      }
                      onClick={handleClickOnPassword}
                      onBlur={logAmplitudeEventCallback(AmplitudeEvents.UserSignInInputChanged, {
                        input_name: "password",
                        valid_field: !form.formState.errors["password"],
                        field_empty: !form.getValues("password"),
                      })}
                    />
                    <Grid marginTop={0.5}>
                      <Link onClick={redirectToResetPassword}>
                        <Typography variant="caption" vibe="primary-main" data-test="forgot-password-button">
                          {t("dashboard", "signin.passwordForgotten2")}
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} marginTop={4}>
            <Button
              data-test="submit-email-button"
              type="submit"
              fullWidth
              variant="filled"
              vibe="brand"
              isLoading={isLoading}
              onClick={onClickSubmit}
            >
              {t("common", "confirm")}
            </Button>
          </Grid>
        </Grid>
      </StyledForm>
    </>
  );
};
